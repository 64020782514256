import { getAllFeatures, getFeatureValueType } from '@vrw/data/src/redux/features/features.selectors'
import { FeatureValueType } from '@vrw/data/src/redux/features/features.types'
import { useAppSelector } from '../../../redux/hooks/hooks'
import { datadogRum } from '@datadog/browser-rum'

export const useFeatures = () => {
  const features = useAppSelector(getAllFeatures)

  return features.reduce((features, feature) => {
    if (feature?.name && feature?.value) {
      const valueType = getFeatureValueType(feature)
      let value = valueType && feature.value[valueType]

      if (value !== undefined) {
        datadogRum.addFeatureFlagEvaluation(feature.name, value)
        if (valueType === FeatureValueType.BOOLEAN_VALUE) {
          value = value ? 'enabled' : 'disabled'
        }
        features.push(`${feature.name}:${value}`)
      }
    }

    return features
  }, [] as string[])
}
