import React from 'react'
import { Page, ActivityBalance, ActivityStatement, HelpCTA } from '../../components'

export const Activity = () => (
  <Page testId="page-activity" title="Activity | Virgin">
    <ActivityBalance />
    <ActivityStatement />
    <HelpCTA />
  </Page>
)
