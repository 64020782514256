import * as braze from '@braze/web-sdk'
import { BRAZE_API_KEY, BRAZE_BASE_URL, ENV } from '../config'
import { apiGetBrazeToken } from '@vrw/data/src/api/braze.token.api'
import { logger } from '@vrw/data/src/utils'
import { callWithRetry } from '@vrw/data/src/utils/httpClient'
import { getViewingRegion } from './viewingRegionWeb'

interface InitBrazeProps {
  areAnalyticalCookiesEnabled: boolean
  isAuthenticated: boolean
  externalId: string | undefined
  setIsBrazeInitialised: () => void
}

enum BRAZE_CUSTOM_ATTRIBUTES {
  VIEWING_REGION = 'vred_viewing_region',
}

export const initBraze = async ({ areAnalyticalCookiesEnabled, isAuthenticated, externalId, setIsBrazeInitialised }: InitBrazeProps) => {
  const updateBrazeJwt = async () => {
    const brazeJwt = await callWithRetry(() => apiGetBrazeToken('web'))
    return brazeJwt
  }

  let brazeToken = undefined

  if (isAuthenticated && areAnalyticalCookiesEnabled) {
    try {
      brazeToken = await updateBrazeJwt()
    } catch (e) {
      logger.error(e)
      brazeToken = ''
    }
  }

  const logEnvs = ['localhost', 'dev', 'staging']
  // list of `DeviceProperties` we want to collect
  const devicePropertyAllowlist = [
    braze.DeviceProperties.OS,
    braze.DeviceProperties.BROWSER,
    braze.DeviceProperties.BROWSER_VERSION,
    braze.DeviceProperties.USER_AGENT,
  ]

  const devicePropertyAllowlistExtended = [
    braze.DeviceProperties.TIME_ZONE,
    braze.DeviceProperties.RESOLUTION,
    braze.DeviceProperties.LANGUAGE,
  ]

  const isBrazeInitialised = braze.initialize(BRAZE_API_KEY!, {
    allowUserSuppliedJavascript: true,
    baseUrl: BRAZE_BASE_URL!,
    devicePropertyAllowlist: areAnalyticalCookiesEnabled
      ? [...devicePropertyAllowlist, ...devicePropertyAllowlistExtended]
      : [...devicePropertyAllowlist],
    enableLogging: logEnvs.includes(ENV!),
    enableSdkAuthentication: isAuthenticated && Boolean(brazeToken),
  })

  if (isBrazeInitialised) {
    setIsBrazeInitialised()
  }

  if (!externalId) {
    braze.getUser()?.setCustomUserAttribute(BRAZE_CUSTOM_ATTRIBUTES.VIEWING_REGION, getViewingRegion())
  }

  if (brazeToken && externalId) {
    braze.changeUser(externalId, brazeToken)

    braze.subscribeToSdkAuthenticationFailures(async () => {
      try {
        const newToken = await updateBrazeJwt()
        braze.setSdkAuthenticationSignature(newToken)
      } catch (e) {
        logger.error(e)
        braze.disableSDK()
      }
    })
  }

  braze.subscribeToContentCardsUpdates((cards) => logger.log(cards))

  const routeAtInitialisation = window.location.href

  braze.subscribeToInAppMessage((inAppMessage) => {
    if (inAppMessage.isControl) {
      return braze.showInAppMessage(inAppMessage)
    }

    const excludedUrls = inAppMessage.extras.hide?.split(',') ?? []

    const shouldShowCard = !excludedUrls.some((url) => routeAtInitialisation.includes(url))

    if (shouldShowCard) {
      return braze.showInAppMessage(inAppMessage)
    }
    return false
  })

  braze.openSession()
}
