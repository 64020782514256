import React, { FC, useEffect } from 'react'
import { getString, formatIsoDateToDateWithMonthString } from '@vrw/data/src/utils'

import { color, layout, FontFamilies } from '../../../style/variables'
import { PATHS } from '../../../router/paths'
import { Page } from '../../../components/Page'
import { H1Header, H2Header } from '../../../components/typography'
import Header from '../../../components/RewardDetails/Header'
import { OrderContent, OrderStatus } from '@vrw/data/src/redux/orders/types'
import { HelpCTA } from '../../../components/HelpCTA'
import { RewardImage } from '../../../components/RewardDetails/RewardImage'
import { media } from '../../../style/media'
import { Points } from '../../../components/Points'
import DashedDivider from '../../../components/DashedDivider'
import { Button } from '../../../components/Buttons/Button'
import { Stars } from '../../../components/icons/Stars'
import { AddressContainer } from '../../../components/AddressChooser/AddressChooser'
import { OrderProps } from './types'
import { RedemptionType } from '@vrw/data/src/redux/rewards/types'
import { useRedNavigate } from 'web/src/dataImplementation/useRedNavigate'

const OrderConfirmation: FC<OrderProps> = ({ order }) => {
  const navigate = useRedNavigate()
  useEffect(() => {
    if (!order) navigate(PATHS.MY_REWARDS_ORDERS)
  })

  const { orderId, content, cost, deliveryAddress, createdDate, trackingUrl, redemptionType, giftMessage, status } = order || {}

  const { title, imageUrl, brandDetails } = (content as OrderContent) || {}
  const { brandName, brandLogo } = brandDetails ?? {}
  const isDonation = redemptionType === RedemptionType.DONATION_LINK
  const isDigitalFulfilment = redemptionType === RedemptionType.DIGITAL_FULFILMENT
  const isWinesFulfilment = redemptionType === RedemptionType.WINES_FULFILMENT
  const isPhysicalFulfilment = redemptionType === RedemptionType.PHYSICAL_SPARKLES
  const isCancelled = status === OrderStatus.CANCELLED
  const isTillo = isDigitalFulfilment && trackingUrl

  return (
    <>
      <style jsx>{`
        .page-order__header-wrapper {
          display: block;
        }
        .page-order__header {
          height: 250px;
        }
        .page-order__header-icon {
          padding: 40px 0 20px;
          text-align: center;
        }
        .page-order__card {
          background: ${color.white};
          margin: -80px auto 0;
          overflow: hidden;
          position: relative;
          width: 100%;
        }
        .page-order__details {
          margin: 100px auto 64px;
          max-width: 650px;
          padding: 0 20px;
        }
        .page-order__divider {
          margin: 64px 0;
        }
        .page-order__block-title {
          color: ${color.textHeader};
          font-family: ${FontFamilies.barlow};
          font-size: 28px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          line-height: 1.14;
          margin-bottom: 26px;
        }
        .page-order__block-body {
          font-size: 20px;
          line-height: 1.6;
          margin-bottom: 26px;
        }
        .page-order__block-button {
          float: right;
        }
        .page-order__points {
          font-size: 42px;
          text-align: center;
          margin-bottom: 24px;
          color: ${color.brandPrimary};
          font-weight: 600;
          font-style: italic;
          line-height: 1.05;
          letter-spacing: 1px;
        }
        .page-order__description {
          font-size: 18px;
          line-height: 1.6;
          margin-bottom: 32px;
          text-align: center;
        }
        .page-order__points span {
          font-size: 20px;
          margin-right: 5px;
        }
        .page-order__created {
          text-align: center;
          font-size: 20px;
        }
        .page-order__status {
          background-color: ${isCancelled ? color.darkPinkBackground : color.lighterGreen};
          border-radius: 4px;
          color: ${isCancelled ? color.errorRed : color.positivePointsGreen};
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 1.5px;
          padding: 4px 5px 6px 8px;
        }
        .tillo-box {
          display: flex;
          align-tems: center;
          justify-content: center;
          margin-bottom: 2px;
        }
        @media ${media.tabletAndHigher} {
          .page-order__header {
            height: 330px;
          }
          .page-order__card {
            border: solid 1px ${color.secondaryButtonBorder};
            border-radius: 8px;
            margin-top: -179px;
            width: ${layout.heroWidth.tablet}px;
          }
          .page-order__details {
            padding: 0;
          }
          .page-order__description {
            font-size: 20px;
          }
        }
        @media ${media.desktopAndHigher} {
          .page-order__header {
            height: 370px;
          }
          .page-order__card {
            margin-top: -179px;
            width: ${layout.heroWidth.desktop}px;
          }
        }
      `}</style>
      <Page testId="page-order" title={`My Rewards | Virgin`}>
        <div className="page-order__header-wrapper">
          <Header>
            <div className="page-order__header">
              <div className="page-order__header-icon">
                <Stars className="link-accounts__success-icon" size={57} color={color.redHover} />
              </div>
              <H1Header color={color.textHeader} textAlign="center" fontStyle="italic" textTransform="uppercase" weight={600}>
                {getString(isCancelled ? 'orders.confirmation.headline.cancelled' : `orders.confirmation.${redemptionType}.headline`)}
              </H1Header>
            </div>
          </Header>
        </div>
        <div className="page-order__card">
          <RewardImage rewardImageUrl={imageUrl} rewardImageAlt={title || ''} brandLogoUrl={brandLogo} brandLogoAlt={brandName ?? ''} />
          <div className="page-order__details">
            <H2Header
              textAlign="center"
              fontStyle="italic"
              textTransform="uppercase"
              weight={600}
              fontSize={{
                mobile: 28,
                tablet: 28,
                desktop: 28,
              }}>
              {title}
            </H2Header>
            <div className="page-order__points">
              <Points value={cost} />
              <span> point{cost !== 1 ? 's' : ''}</span>
            </div>
            {isTillo && (
              <div className="tillo-box" aria-label="tillo_order" data-testid="tillo_order">
                <Button
                  data-testid="tillo_order"
                  text={getString('orders.confirmation.order.tillo.view')}
                  buttonType="submit"
                  isFullWidth={false}
                  display="inline-block"
                  isPrimary
                  onClick={() => {
                    window.open(trackingUrl, '_blank')
                  }}
                />
              </div>
            )}
            {!isTillo && (
              <div className="page-order__description">
                {getString(isCancelled ? 'orders.confirmation.description.cancelled' : `orders.confirmation.${redemptionType}.description`)}
              </div>
            )}
            {!isCancelled && (
              <div className="page-order__created">
                <span>
                  {isDonation ? getString('orders.confirmation.donation_link.placed') : getString('orders.confirmation.order.placed')}:
                </span>{' '}
                {createdDate && formatIsoDateToDateWithMonthString(createdDate, true)}
              </div>
            )}
            {!isDonation && !isDigitalFulfilment && (
              <>
                <div data-testid={'order-physical'} className="page-order__divider">
                  <DashedDivider />
                </div>
                {(isWinesFulfilment || isPhysicalFulfilment) && (
                  <div className="page-order__block">
                    <div className="page-order__block-title">{getString('orders.confirmation.delivery.orderNumberTitle')}</div>
                    <div className="page-order__block-body">
                      {orderId}
                      {trackingUrl && (
                        <div className="page-order__block-button">
                          <Button
                            display="inline-block"
                            isFullWidth={false}
                            onClick={() => () => window.open(trackingUrl, '_blank')}
                            text={getString('orders.confirmation.button.trackOrder')}
                            dataAttributes={{
                              testid: 'track-order-button',
                            }}
                            hasBorder
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {deliveryAddress && !isCancelled && (
                  <div className="page-order__block">
                    <div className="page-order__block-title">{getString('orders.confirmation.delivery.deliveryAddressTitle')}</div>
                    <div className="page-order__block-body">
                      <AddressContainer address={deliveryAddress} />
                    </div>
                  </div>
                )}
                {isWinesFulfilment && (
                  <div className="page-order__block">
                    <div className="page-order__block-title">{getString('orders.confirmation.delivery.statusTitle')}</div>
                    <div className="page-order__block-body">
                      <span className="page-order__status">{status}</span>
                    </div>
                  </div>
                )}
                {!isCancelled && (
                  <>
                    {isWinesFulfilment && (
                      <div className="page-order__block">
                        <div className="page-order__block-title">{getString('orders.confirmation.delivery.deliveryTimeTitle')}</div>
                        <div className="page-order__block-body" data-dd-privacy="mask">
                          {getString('orders.checkout.wines.deliveryInfo')}
                        </div>
                      </div>
                    )}
                    {giftMessage && (
                      <div className="page-order__block">
                        <div className="page-order__block-title">{getString('orders.confirmation.wines_fulfilment.giftMessageTitle')}</div>
                        <div className="page-order__block-body" data-dd-privacy="mask">
                          {giftMessage}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <HelpCTA />
      </Page>
    </>
  )
}

export { OrderConfirmation }
